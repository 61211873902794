<template>
  <div class="faq">
    <div
        class="faq-item"
        v-for="(item, index) in items"
        :key="`faq-item-${item.question}`"
        :class="{ open: item.open || !index }"
    >
      <component :is="item.tag" @click="item.open = !item.open" class="faq-item-question">
        {{ item.question }}
        <img v-if="!item.hideArrow" class="faq-arrow" src="../../assets/icons/index/faq-arrow.svg" alt=""/>
      </component>
      <div class="faq-item-answer">
        <template v-if="typeof item.answer === 'string'">
          <div v-html="item.answer"></div>
        </template>
        <template v-else>
          <div v-for="content in item.answer" :key="content.content">
            <template v-if="content.type === 'block'">
              <div>{{ content.content }}</div>
            </template>
            <template v-else-if="content.type === 'list'">
              <ul>
                <li v-for="listItem in content.content" :key="listItem.content">{{ listItem.content }}</li>
              </ul>
            </template>
            <template v-else-if="content.type === 'orderedList'">
              <ol>
                <li v-for="listItem in content.content" :key="listItem.content">{{ listItem.content }}</li>
              </ol>
            </template>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
const domains = require("../../json/domains.json");
const faqContent = require("../../json/faqContent.json");
const faqContentAuth = require("../../json/faqContentAuth.json");

export default {
  name: "FAQ",
  data() {
    return {items: []};
  },
  computed: {
    isAuth() {
      return this.$store.state.auth;
    },
  },
  methods: {
    initializeData() {
      const currentDomain = window.location.hostname;
      const category = this.getCategoryByDomain(currentDomain, domains);

      if (category) {
        const items = this.isAuth ? faqContentAuth[category] : faqContent[category];
        this.items = this.parsedItems(items);
      }
    },
    parsedItems(items) {
      return items.map((item) => {
        if (typeof item.answer === "string") {
          return {...item, answer: item.answer};
        } else if (Array.isArray(item.answer)) {
          return {...item, answer: item.answer};
        }
        return item;
      });
    },
    getCategoryByDomain(domain, domains) {
      for (const category in domains) {
        if (domains[category].includes(domain)) {
          return category;
        }
      }
      return null;
    },
  },
  created() {
    this.initializeData();
  },
};
</script>

<style scoped lang="scss">
.faq {
  color: white;
  background-color: #0d0d17;
  margin-left: -10px;
  margin-right: -10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 27.8px;

  &-item {
    border-bottom: 2px solid #32334b;

    &:last-child {
      border: none;
    }

    h1, h2, h3 {
      font-size: 15px;
      font-weight: normal;
    }

    &-question {
      padding: 17.2px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;


      div {
        font-size: 15px;
        font-weight: 600;
        line-height: 17.9px;
      }

      .faq-arrow {
        transition: transform 200ms;
      }
    }

    &:first-child {
      .faq-arrow {
        display: none;
      }
    }

    &-answer {
      font-size: 12px;
      font-weight: 400;
      line-height: 13.53px;
      height: 0;
      transition: opacity 600ms;
      overflow: hidden;
      opacity: 0;
    }

    &.open {
      .faq-item-question {
        padding: 17.2px 0 10px 0;
        position: relative;

        .faq-arrow {
          transform: rotate(180deg);
        }
      }

      .faq-item-answer::v-deep {
        height: auto;
        padding-bottom: 26px;
        opacity: 1;

        div {
          margin-bottom: 14px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        ul {
          padding-left: 18px;
          margin-top: 14px;
          margin-bottom: 14px;

          li {
            list-style: none;
            position: relative;

            &:before {
              content: "";
              border: 1px solid white;
              border-radius: 50%;
              width: 1px;
              height: 1px;
              position: absolute;
              left: -11px;
              top: 7px;
              transform: translateY(-50%);
            }
          }
        }

        ol {
          padding-left: 18px;
          margin-top: 14px;
          margin-bottom: 14px;
        }
      }
    }
  }
}
</style>
